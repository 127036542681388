<template>
    <el-dialog :visible.sync="visible" title="选择车辆" width="80%">
        <jy-query :model="form" ref="form" labelWidth="170px" class="query_area" size="small">
            <jy-query-item label="车牌号" prop="plateNum">
                <el-input v-model="form.plateNum"></el-input>
            </jy-query-item>
            <jy-query-item label="所属机构" prop="orgId">
                <div @click="selectInstitutions">
                    <el-input placeholder="请选择" v-model="form.orgNa"></el-input>
                </div>
            </jy-query-item>
            <jy-query-item label="发车日是否已有调度任务" prop="hasWork">
                <el-select v-model="form.hasWork">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="是" value="1"></el-option>
                    <el-option label="否" value="0"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="调度发车日状态" prop="rStatus">
                <el-select v-model="form.rStatus">
                    <el-option label="全部" value=""></el-option>
                    <el-option v-for="r in rStatusList" :key="r.value" :label="r.label" :value="r.value"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="车辆类型" prop="vType">
                <el-select v-model="form.vType">
                    <el-option label="全部" :value="null"></el-option>
                    <el-option v-for="t in vTypeList" :key="t.iValue" :label="t.iValue" :value="t.rValue"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="车辆型号" prop="model">
                <el-input v-model="form.model"></el-input>
            </jy-query-item>
            <jy-query-item label="燃料种类" prop="energyT">
                <el-select v-model="form.energyT">
                    <el-option label="全部" value=""></el-option>
                    <el-option v-for="t in energyTList" :key="t.iValue" :label="t.iValue" :value="t.iValue"></el-option>
                </el-select>
            </jy-query-item>
            <template slot="rightCol">
                <el-button size="small" type="primary" @click="oncheck">查询</el-button>
                <el-button size="small" type="primary" @click="resetForm('form')">重置</el-button>
            </template>
        </jy-query>

        <div class="jy-table" v-loading="loading">
            <jy-table :data="dataList">
                <jy-table-column label="序号" type="index"></jy-table-column>
                <jy-table-column label="车牌号" prop="plateNum"></jy-table-column>
                <jy-table-column label="所属机构" prop="orgNa"></jy-table-column>
                <jy-table-column label="发车日是否已有调度任务">
                    <template v-slot="scope">{{ scope.row.hasWork == "1" ? "是" : "否" }}</template>
                </jy-table-column>
                <jy-table-column label="调度发车日状态" prop="rStatus">
                    <template v-slot="scope">{{ formatRStatus(scope.row.orderStatus) }}</template>
                </jy-table-column>
                <jy-table-column label="调度发车时间" prop="pTime"></jy-table-column>
                <jy-table-column label="实际发车时间" prop="rTime"></jy-table-column>
                <jy-table-column label="车辆类型" prop="vType">
                    <template v-slot="scope">{{ formatVType(scope.row.vType) }}</template>
                </jy-table-column>
                <jy-table-column label="车牌品牌" prop="vendor"></jy-table-column>
                <jy-table-column label="车辆型号" prop="model"></jy-table-column>
                <jy-table-column label="燃烧种类" prop="energyT"></jy-table-column>
                <jy-table-column label="总质量（kg）" prop="wTotal"></jy-table-column>
                <jy-table-column label="整备质量（kg）" prop="wCurb"></jy-table-column>
                <jy-table-column label="核定载质量（kg）" prop="wLoad"></jy-table-column>
                <jy-table-column label="外型尺寸（mm）" prop="sizeT"></jy-table-column>
                <jy-table-column label="货厢尺寸（mm）" prop="sizeBox"></jy-table-column>
                <jy-table-column label="车辆容积（方）" prop="volume"></jy-table-column>
                <jy-table-column label="准乘人数（人）" prop="pasNu"></jy-table-column>
                <jy-table-column label="备注" prop="remark"></jy-table-column>
                <jy-operate v-bind:list="operateList" width="80"></jy-operate>
            </jy-table>
            <jy-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pageIndex"
                :page-size="pageSize"
                :total="total"
            ></jy-pagination>
        </div>

        <institutions-tree :showType="[1]" :modal="false" ref="institutions" @addInstitutions="addInstitutions" title="选择所属机构"></institutions-tree>
    </el-dialog>
</template>

<script>
    import institutionsTree from "@/components/pages/admin/common/dialog-tree/institutions_tree";
    export default {
        created() {
            this.initDicts();
        },
        methods: {
            initDicts() {
                this.$http.post("/dict/getDictValuesByDictId", "dictvehicletype").then(res => {
                    this.vTypeList = res.detail;
                });
                this.$http.post("/dict/getDictValuesByDictId", "dictfueltype").then(res => {
                    this.energyTList = res.detail;
                });
            },
            formatVType(type) {
                const t = this.vTypeList.find(v => v.rValue == type);
                if (t) {
                    return t.iValue;
                }
                return "-";
            },
            init(query) {
                this.visible = true;
                Object.assign(this.form, query);
                this.getList();
            },
            addInstitutions(data) {
                this.form.orgId = data.orgId;
                this.form.orgNa = data.orgNa;
            },
            // 选择机构树
            selectInstitutions() {
                this.$refs.institutions.init();
            },
            oncheck() {
                this.pageIndex = 1;
                this.getList();
                console.log("更新");
            },
            formatVTypeValue(val) {
                return val === null ? null : Number(val);
            },
            getList() {
                this.loading = true;
                let url = "/base/vehicle/pageQuery";
                let params = {
                    vehicleInfo: {
                        ...this.form,
                        vType: this.formatVTypeValue(this.form.vType)
                    },
                    hasWork: this.form.hasWork,
                    workStatus: this.form.rStatus,
                    pTime: this.form.pTime,
                    pageIndex: this.pageIndex,
                    pageSize: this.pageSize
                };
                this.$http
                    .post(url, params)
                    .then(({ detail }) => {
                        this.dataList = detail.list.map(v => {
                            return {
                                ...v.vehicleAttach,
                                ...v.vehicleInfo,
                                ...v.sysOrg,
                                pTime: v.scheduleOrder ? v.scheduleOrder.pTime : "",
                                rTime: v.scheduleOrder ? v.scheduleOrder.rTime : "",
                                orderStatus: v.scheduleOrder ? v.scheduleOrder.status : "",
                                hasWork: v.scheduleOrder ? "1" : "0",
                                remark: v.vehicleEx ? v.vehicleEx.remark : "",
                                status:this.formatStatus(v)
                            };
                        });
                        this.total = detail.total;
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            formatPTime(v){
                if (v.scheduleOrder) {
                    return v.scheduleOrder.pTime
                }
                if (v.dealInfo) {
                    return v.dealInfo.dealT
                }
            },
            formatStatus(v){
                if (v.scheduleOrder) {
                    return v.scheduleOrder.status
                }
                if (v.dealInfo) {
                    return v.dealInfo.status
                }
                return ''
            },
            // 重置
            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.form.orgNa = "";
                this.oncheck();
            },
            // 分页
            handleSizeChange(val) {
                this.pageSize = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.pageIndex = val;
                this.getList();
            },
            handleSelect(row) {
                this.$emit("select", row);
                this.visible = false;
            },
            formatRStatus(val) {
                const one = this.rStatusList.find(v => v.value == val);
                return one ? one.label : "-";
            }
        },
        data() {
            return {
                visible: false,

                loading: false,
                dataList: [],
                total: 0,
                rStatusList: [
                    {
                        label: "已取消",
                        value: "2"
                    },
                    {
                        label: "已完成",
                        value: "3"
                    },
                    {
                        label: "待发车",
                        value: "0"
                    },
                    {
                        label: "运输中",
                        value: "1"
                    }
                ],

                form: {
                    plateNum: "",
                    orgId: "",
                    orgNa: "",
                    status: "1",
                    vType: null,
                    energyT: "",
                    model: "",
                    hasWork: "",
                    rStatus: ""
                },

                pageIndex: 1,
                pageSize: 10,

                vTypeList: [],
                energyTList: [],
                operateList: [
                    {
                        name: "选择",
                        icon: "el-icon-edit-outline",
                        fun: this.handleSelect,
                        isShow: () => {
                            return true;
                        }
                    }
                ]
            };
        },
        components: {
            institutionsTree
        }
    };
</script>
<style></style>
