<template>
    <el-dialog :visible.sync="visible" title="选择司机" width="80%">
        <jy-query ref="form" :model="form" labelWidth="160px" class="query_area" size="small">
            <jy-query-item label="姓名" size="small" prop="cName">
                <el-input v-model="form.cName"></el-input>
            </jy-query-item>
            <jy-query-item label="所属机构" prop="orgId">
                <div @click="selectInstitutions">
                    <el-input placeholder="请选择" v-model="form.orgNa"></el-input>
                </div>
            </jy-query-item>
            <jy-query-item label="调度发车日状态" prop="rStatus">
                <el-select v-model="form.rStatus">
                    <el-option label="全部" value=""></el-option>
                    <el-option v-for="r in rStatusList" :key="r.value" :label="r.label" :value="r.value"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="发车日是否有调度任务" prop="hasWork">
                <el-select v-model="form.hasWork">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="是" value="1"></el-option>
                    <el-option label="否" value="0"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="性别" prop="gender">
                <el-select v-model="form.gender">
                    <el-option label="全部" value=""></el-option>
                    <el-option label="女" value="0"></el-option>
                    <el-option label="男" value="1"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item label="联系电话" prop="phone">
                <el-input v-model="form.phone"></el-input>
            </jy-query-item>
            <jy-query-item label="准驾车型" prop="dlType">
                <el-select v-model="form.dlType">
                    <el-option v-for="t in driveTypes" :key="t.rValue" :label="t.iValue" :value="t.rValue"></el-option>
                </el-select>
            </jy-query-item>
            <template slot="rightCol">
                <el-button size="small" type="primary" @click="oncheck">查询</el-button>
                <el-button size="small" type="primary" @click="resetForm('form')">重置</el-button>
            </template>
        </jy-query>
        <div class="jy-table" v-loading="loading">
            <jy-table :data="dataList" ref="driver_table">
                <jy-table-column type="selection" width="55" v-if="mode === 'check'"></jy-table-column>
                <jy-table-column label="序号" type="index"></jy-table-column>
                <jy-table-column label="姓名" prop="cName"></jy-table-column>
                <jy-table-column label="所属机构" prop="orgNa"></jy-table-column>
                <jy-table-column label="发车日是否已有调度任务">
                    <template v-slot="scope">{{ scope.row.hasWork == "1" ? "是" : "否" }}</template>
                </jy-table-column>
                <jy-table-column label="调度发车日状态">
                    <template v-slot="scope">{{ formatRStatus(scope.row.orderStatus) }}</template>
                </jy-table-column>
                <jy-table-column label="调度发车时间" prop="pTime"></jy-table-column>
                <jy-table-column label="实际发车时间" prop="rTime"></jy-table-column>
                <jy-table-column label="性别" prop="gender">
                    <template v-slot="scope">{{ scope.row.gender == "1" ? "男" : "女" }}</template>
                </jy-table-column>
                <jy-table-column label="准驾车型" prop="dlType">
                    <template v-slot="scope">{{ formatDlType(scope.row.dlType) }}</template>
                </jy-table-column>
                <jy-table-column label="联系电话" prop="phone"></jy-table-column>
                <jy-operate v-if="mode === 'radio'" v-bind:list="operateList" width="80"></jy-operate>
            </jy-table>
            <jy-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pageIndex"
                :page-size="pageSize"
                :total="total"
            ></jy-pagination>
        </div>

        <div class="handle_buttons" v-if="mode === 'check'">
            <el-button type="primary" size="small" @click="makeSure">确定</el-button>
            <el-button size="small" @click="visible = false">取消</el-button>
        </div>

        <institutions-tree :showType="[1]" :modal="false" ref="institutions" @addInstitutions="addInstitutions" title="选择所属机构"></institutions-tree>
    </el-dialog>
</template>

<script>
    import institutionsTree from "@/components/pages/admin/common/dialog-tree/institutions_tree";
    export default {
        props: {
            mode: {
                type: String,
                default: "check"
            },
            checkFun:{
                type:Function,
                default:()=>{
                    return true
                }
            }
        },
        created() {
            this.initDicts();
        },
        methods: {
            formatRStatus(val) {
                const one = this.rStatusList.find(v => v.value == val);
                return one ? one.label : "-";
            },
            formatDlType(val) {
                const one = this.driveTypes.find(v => v.rValue == val);
                return one ? one.iValue : "-";
            },
            initDicts() {
                this.$http.post("/dict/getDictValuesByDictId", "dictdrivingmodel").then(res => {
                    this.driveTypes = res.detail;
                });
            },
            init(query) {
                this.visible = true;
                Object.assign(this.form, query);
                this.getList();
            },
            addInstitutions(data) {
                this.form.orgId = data.orgId;
                this.form.orgNa = data.orgNa;
            },
            // 选择机构树
            selectInstitutions() {
                this.$refs.institutions.init();
            },
            oncheck() {
                this.pageIndex = 1;
                this.getList();
                console.log("更新");
            },
            getList() {
                this.loading = true;
                let url = "/base/driver/queryPage";
                this.$http
                    .post(
                        url,
                        {
                            driverInfo: this.form,
                            hasWork: this.form.hasWork,
                            workStatus: this.form.rStatus,
                            pTime: this.form.pTime,
                            pageIndex: this.pageIndex,
                            pageSize: this.pageSize
                        },
                        { isRequestParam: false }
                    )
                    .then(({ detail }) => {
                        this.dataList = detail.list.map(v => {
                            return {
                                ...v.driverAttach,
                                ...v.driverInfo,
                                ...v.sysOrg,
                                pTime: v.scheduleOrder ? v.scheduleOrder.pTime : "",
                                rTime: v.scheduleOrder ? v.scheduleOrder.rTime : "",
                                orderStatus: v.scheduleOrder ? v.scheduleOrder.status : "",
                                hasWork: v.scheduleOrder ? "1" : "0"
                            };
                        });
                        this.total = detail.total;
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            // 重置
            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.form.orgNa = "";
                this.oncheck();
            },
            // 分页
            handleSizeChange(val) {
                this.pageSize = val;
                this.getList();
            },
            handleCurrentChange(val) {
                this.pageIndex = val;
                this.getList();
            },
            handleSelect(row) {
                this.$emit("select", row);
                this.visible = false;
            },
            makeSure() {
                const rows = this.$refs.driver_table.selection;
                if (!rows.length) {
                    this.$message.warning({
                        message: "请选择司机"
                    });
                    return;
                }
                let go = true
                if (this.checkFun) {
                    go = this.checkFun(rows)
                }
                if (go) {
                    this.$emit("select", rows);
                    this.visible = false;
                }
            }
        },
        data() {
            return {
                visible: false,

                loading: false,
                dataList: [],
                total: 0,

                rStatusList: [
                    {
                        label: "已取消",
                        value: "2"
                    },
                    {
                        label: "已完成",
                        value: "3"
                    },
                    {
                        label: "待发车",
                        value: "0"
                    },
                    {
                        label: "运输中",
                        value: "1"
                    }
                ],

                form: {
                    cName: "",
                    orgId: "",
                    orgNa: "",
                    sState: "1",
                    gender: "",
                    phone: "",
                    dlType: "",
                    hasWork: "",
                    rStatus: ""
                },

                pageIndex: 1,
                pageSize: 10,

                driveTypes: [],

                operateList: [
                    {
                        name: "选择",
                        icon: "el-icon-edit-outline",
                        fun: this.handleSelect,
                        isShow: () => {
                            return true;
                        }
                    }
                ]
            };
        },
        components: {
            institutionsTree
        }
    };
</script>
<style scoped>
    .handle_buttons {
        text-align: center;
    }
</style>
